import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"

import Section from "../../elements/Section"
import Container from "../../elements/Container"
import globalVariables from "../../globalVariables"

const List = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
`

const Item = styled.li`
  flex: 0 0 25%;
  list-style: none;
  position: relative;
  a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .gatsby-image-wrapper {
      transition: all 0.4s ease-in-out;
    }
    &:hover .gatsby-image-wrapper {
      transform: rotate(360deg);
      /* transform: scale(1.2); */
    }
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 70%;
    background: ${props => props.theme.secondary};
    /* @media (min-width: ${globalVariables.minTablet}) {
      left: -1rem;
    } */
  }
  &:last-child::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 70%;
    background: ${props => props.theme.secondary};
    /* @media (min-width: ${globalVariables.minTablet}) {
      right: -1rem;
    } */
  }
`

const Text = styled.p`
  font-family: Rajdhani;
  font-size: 18px;
  text-transform: uppercase;
  color: ${props => props.theme.white};
  margin-top: 10px;
  display: none;
  @media (min-width: ${globalVariables.minTablet}) {
    line-height: 1.3;
    margin-bottom: 0;
    display: block;
  }
`

const BannerList = ({ balance, poids, coeur, muscle }) => {
  return (
    <Section color="ternary" padding="20px 0">
      <Container>
        <List>
          <Item>
            <Link to="/coaching-sportif-personnalise/coach-sportif-perte-de-poids">
              <Img fixed={balance} />
              <Text>Perte de poids</Text>
            </Link>
          </Item>
          <Item>
            <Link to="/coaching-sportif-personnalise/coach-musculation">
              <Img fixed={muscle} />
              <Text>Musculation</Text>
            </Link>
          </Item>
          <Item>
            <Link to="/coaching-sportif-personnalise/coach-tonification">
              <Img fixed={poids} />
              <Text>Tonification</Text>
            </Link>
          </Item>
          <Item>
            <Link to="/coaching-sportif-personnalise/coach-bien-etre">
              <Img fixed={coeur} />
              <Text>Bien-être</Text>
            </Link>
          </Item>
        </List>
      </Container>
    </Section>
  )
}

export default BannerList
