import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { default as BannerHome } from "../components/Home/Banner"
import BannerList from "../components/Home/BannerList"
import Presentation from "../components/Home/Presentation"
import CoachingList from "../components/Commun/CoachingList"
import Temoignages from "../components/Commun/Temoignages"
import Social from "../components/Commun/Social"
import Banner from "../components/Commun/Banner"

const IndexPage = ({ data }) => {
  const prismicData = getPrismicData(data)

  return (
    <Layout
      isNavbarTransparent
      kevinFooter={data.kevinFooter.childImageSharp.fixed}
    >
      <SEO
        title="Coach Sportif personnel à Nantes ou à distance"
        description="Ma méthode de coaching comprend un programme d'entrainement sur mesure pour vous aider à atteindre votre objectif, des conseils nutritions et un suivi personnalisé."
      />
      <BannerHome
        title={prismicData.banner.titre1}
        baseline={prismicData.banner.titre2}
        imageBanner={data.banner.childImageSharp.fluid}
        imageHeight="700px"
        imageMobileHeight="550px"
      />
      <BannerList
        balance={data.balance.childImageSharp.fixed}
        muscle={data.muscle.childImageSharp.fixed}
        poids={data.poids.childImageSharp.fixed}
        coeur={data.coeur.childImageSharp.fixed}
      />
      <Presentation
        title={prismicData.contenuPrincipal.titre}
        text={prismicData.contenuPrincipal.contenu}
        imagePresentation={prismicData.contenuPrincipal.image}
        buttonText="En savoir plus"
        buttonUrl="/a-propos"
        position="inversed"
      />
      <CoachingList
        items={prismicData.coaching.items}
        buttonText="Voir mes coachings"
        buttonUrl="/coaching-sportif-personnalise"
        colorSection="ternary"
        padding="50px 0"
        imageEntrainement={data.coachingListEntrainement.childImageSharp.fixed}
        imageNutrition={data.coachingListNutrition.childImageSharp.fixed}
        imageSuivi={data.coachingListSuivi.childImageSharp.fixed}
      />
      <Temoignages items={prismicData.temoignage.items} />
      <Social />
      <Banner
        title={prismicData.accroche.contenu}
        imageBanner={data.accroche.childImageSharp.fluid}
        imageHeight="450px"
        imageMobileHeight="350px"
        buttonText="Prendre RDV"
        buttonUrl="/contact"
      />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    prismicAccueil {
      data {
        image_contenu_principal {
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        body {
          ... on PrismicAccueilBodyAccroche {
            id
            primary {
              contenu {
                html
                text
              }
            }
          }
          ... on PrismicAccueilBodyBanniere {
            id
            primary {
              titre_1 {
                html
                text
              }
              titre_2 {
                html
                text
              }
            }
          }
          ... on PrismicAccueilBodyCoaching {
            items {
              contenu {
                html
                text
              }
              titre {
                html
                text
              }
            }
          }
          ... on PrismicAccueilBodyContenuPrincipal {
            id
            primary {
              contenu {
                html
                text
              }
              titre {
                html
                text
              }
            }
          }
          ... on PrismicAccueilBodyTemoignage {
            id
            items {
              contenu {
                html
                text
              }
              photo {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              nom {
                html
                text
              }
            }
          }
        }
      }
    }
    banner: file(relativePath: { eq: "accueil-kevcoachingpro.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 3000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    accroche: file(relativePath: { eq: "accroche.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    balance: file(relativePath: { eq: "icones/scale-white.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 30, height: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    coeur: file(relativePath: { eq: "icones/heart-white.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 30, height: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    muscle: file(relativePath: { eq: "icones/muscle-white.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 30, height: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    poids: file(relativePath: { eq: "icones/dumbbell-white.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 30, height: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    coachingListEntrainement: file(
      relativePath: { eq: "programme-entrainement.jpeg" }
    ) {
      childImageSharp {
        fixed(quality: 100, width: 200, height: 200) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    coachingListNutrition: file(relativePath: { eq: "nutrition.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 200, height: 200) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    coachingListSuivi: file(relativePath: { eq: "suivi-perso.jpeg" }) {
      childImageSharp {
        fixed(quality: 100, width: 200, height: 200) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    kevinFooter: file(relativePath: { eq: "Kevin-footer.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

function getPrismicData(data) {
  return {
    banner: {
      titre1: data.prismicAccueil.data.body[0].primary.titre_1.text,
      titre2: data.prismicAccueil.data.body[0].primary.titre_2.text,
    },
    contenuPrincipal: {
      titre: data.prismicAccueil.data.body[1].primary.titre.text,
      contenu: data.prismicAccueil.data.body[1].primary.contenu.html,
      image: data.prismicAccueil.data.image_contenu_principal.fluid,
    },
    coaching: {
      items: data.prismicAccueil.data.body[2].items,
    },
    temoignage: {
      items: data.prismicAccueil.data.body[3].items,
    },
    accroche: {
      contenu: data.prismicAccueil.data.body[4].primary.contenu.text,
    },
  }
}
